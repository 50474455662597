import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ModuleMenu from '../../components/Modules/ModuleMenu'
import {THERMOCYCLER_MODULE_DATA} from '../../components/Modules/module-data'
import ModuleContent from '../../components/Modules/ModuleContent'

const ThermocyclerModulePage = () => (
  <Page>
    <SEO
      title="Thermocycler Module | Opentrons OT-2 Modular Hardware"
      description="The Opentrons Thermocycler is the most affordable fully automated thermocycler ever. Fits inside the OT-2 pipetting robot. Comparable to machines 5x its price. "
    />
    <ModuleMenu currentUrl={THERMOCYCLER_MODULE_DATA.basic.url} />
    <ModuleContent module={THERMOCYCLER_MODULE_DATA} />
  </Page>
)

export default ThermocyclerModulePage
